import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import qs from 'querystring';
import { EmptyLayout, Paragraph as P, Link as A } from '@periodica/ui-kit';
import { YandexTableau } from '@components/Layouts/YandexTableau';
import Layout from '../../components/Layouts/Layout';
import { OneColumn } from '../../components/Layouts/OneColumn';
import SEO from '../../components/Layouts/SEO';

export function Head() {
  return (
    <>
      <SEO title="Оплата еще не пришла" description="Во время оплаты заказа произошла ошибка" />;
      <YandexTableau />
    </>
  );
}

function PaymentPendingPage() {
  const [retryUrl, setRetryUrl] = useState('');

  useEffect(() => {
    const query = typeof window !== 'undefined' ? window.location.search.replace('?', '') : null;
    if (query) {
      const result = qs.parse(query);
      if (result.retryUrl) {
        setRetryUrl(result.retryUrl);
      }
    }
  }, []);

  return (
    <Layout>
      <OneColumn>
        <EmptyLayout
          hasIcon={false}
          title="Заказ еще не оплачен"
          description={
            <P size="inherit">
              Если вы&nbsp;оплатили заказ, обратите внимание, что мы ещё не&nbsp;получили информацию
              об&nbsp;успешной оплате.{' '}
              {retryUrl ? (
                <>
                  Попробуйте <A href={`${retryUrl}`}>проверить статус заказа</A> еще раз или&nbsp;
                  <A asChild>
                    <Link to="/contacts">свяжитесь с&nbsp;нами</Link>
                  </A>{' '}
                  любым удобным&nbsp;способом.
                </>
              ) : (
                <>
                  Пожалуйста,{' '}
                  <A asChild>
                    <Link to="/contacts">свяжитесь с&nbsp;нами</Link>
                  </A>{' '}
                  любым удобным&nbsp;способом.
                </>
              )}
            </P>
          }
        />
      </OneColumn>
    </Layout>
  );
}

export default PaymentPendingPage;
